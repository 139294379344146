<template>
  <div class="dashboard">
    <div class="row"></div>
    <div class="row my-3">
      <div class="col-12 section-head">
        <div class="row">
          <div class="col-12 col-lg-6 d-flex align-items-center">
            <h3 class="section-head-title ps-0 mb-3 mb-lg-0">
              Conoce las cifras más importantes de <strong>Reconoce</strong>
            </h3>
          </div>
          <div class="col-12 col-lg-6">
            <div class="row">
              <div
                class="col-12 col-lg-3 col-xl-4 d-flex align-items-center justify-content-lg-end text-secondary"
              >
                Filtrar por periodo:
              </div>
              <div class="col-12 col-lg-9 col-xl-8">
                <div class="row">
                  <div class="col-6">
                    <InputDate
                      :max="range.date_end"
                      placeHolder="Inicio"
                      @dateEmit="captureDateStart"
                    />
                  </div>
                  <div class="col-6">
                    <InputDate
                      :min="range.date_start"
                      placeHolder="Término"
                      @dateEmit="captureDateEnd"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-12 col-lg-6">
            <!--CARD NUMBERS-->
            <div class="card card-numbers border-round-10 card-shadow mb-3">
              <div class="card-body">
                <div
                  class="card-numbers-head d-flex align-items-center color-main"
                >
                  <img
                    class="card-numbers-head-icon px-2"
                    src="@/assets/img/hotels/Icono_Hoteleria.png"
                    alt=""
                  />
                  <div class="card-numbers-head-num">
                    {{ hotel_dash.total_atendidas }}
                  </div>
                  <div class="card-numbers-head-text">
                    Solicitudes <br />
                    atendidas
                  </div>
                </div>
                <div class="card-numbers-text mt-3">
                  Cantidad de solicitudes atendidas en Hotelería
                </div>
              </div>
            </div>

            <!--CARD PIE-->
            <Loading v-if="loading" pixeles="150px" />
            <template v-if="!loading">
              <div
                class="card card-pie border-round-10 card-shadow mb-3 mb-lg-0"
              >
                <div class="card-body">
                  <div class="row">
                    <div class="col-7">
                      <div class="card-pie-graph">
                        <apexchart
                          :key="key_dash"
                          :options="chartOptions"
                          :series="series"
                        >
                        </apexchart>
                      </div>
                    </div>
                    <div class="col-5">
                      <div
                        class="card-pie-legend d-flex flex-column justify-content-center align-items-start ps-3"
                      >
                        <h5 class="mb-3">Estadísticas por estado</h5>
                        <div
                          class="card-pie-legend-text d-flex align-items-center"
                          v-for="(state, i) in hotel_dash.total_estados"
                          :key="i"
                        >
                          <div
                            class="card-pie-legend-dot"
                            :style="`background-color: ${state.color};`"
                          ></div>
                          {{ state.nombre_estado }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
          <div class="col-12 col-lg-6">
            <div class="row">
              <!--CARD BARS-->
              <Loading v-if="loading" pixeles="150px" />
              <template v-if="!loading">
                <div
                  class="col-12 col-xl-6 mb-3"
                  v-for="(data, i) in hotel_dash.total_servicios"
                  :key="i"
                >
                  <div class="card card-shadow card-bars border-round-10">
                    <div class="card-body">
                      <div class="card-bars-title">
                        {{ data.nombre_servicio }}
                      </div>
                      <div class="card-bars-count my-2">
                        <div class="card-bars-count-num pe-2">
                          {{ data.total }}
                        </div>
                        <div class="card-bars-count-val">solicitudes</div>
                      </div>
                      
                      <div class="card-bars-bar">
                        <div class="card-bars-bar-back bg-blue-gray"></div>
                        <div
                          class="card-bars-bar-front bg-blue"
                          :style="`width:${100*(data.total/hotel_dash.total_solicitudes )}%;`"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions } from "vuex";
//FF
import InputDate from "@/components/forms/InputDate.vue";
import Loading from "@/components/Loading.vue";

export default {
  components: {
    //FF
    InputDate,
    Loading,
  },
  data() {
    const { date_start, date_end } = this.calculateDateRange();
    return {
      id_empresa: this.$ls.get("user").empresa[0].id_empresa,
      key_dash: 1234,
      valores: [],
      value_colors: [],
      //FF
      loading: true,
      range: {
        date_start: date_start,
        date_end: date_end,
        mask_start: moment(date_start).format("YYYY-MM-DD"),
        mask_end: moment(date_end).format("YYYY-MM-DD"),
      },
      hotel_dash: {},
      series: [],
      chartOptions: {
        labels: [],
        colors: [],
        chart: {
          type: "donut",
          fontFamily: "Product Sans Regular",
          height: "100%",
          dropShadow: {
            enabled: false,
          },
        },
        dataLabels: {
          style: {
            colors: ["#fff"],
            fontSize: "16px",
            fontWeight: "bold",
          },
          dropShadow: {
            enabled: false,
          },
        },
        legend: {
          show: false
        },
        stroke: {
          show: false,
        },
        plotOptions: {
          pie: {
            startAngle: 0,
            endAngle: 360,
            customScale: 1,
            dataLabels: {
              offset: 0,
              minAngleToShowLabel: 10,
            },
            donut: {
              size: "50%",
              background: "transparent",
            },
          },
        },
      },
    };
  },
  async mounted() {
    this.getData()
  },

  methods: {
    ...mapActions("hotelsModule", [
      "getDataHoteleria",
      "limiteSolicitudesUsuario",
    ]),
    calculateDateRange() {
      const today = moment();
      const sixMonthsAgo = today.clone().subtract(6, 'months');
      const sixMonthsLater = today.clone().add(6, 'months');
      return {
        date_start: sixMonthsAgo.toDate(),
        date_end: sixMonthsLater.toDate(),
      };
    },
    // get
    async getData() {
      this.loading = true;
      this.hotel_dash = await this.getDataHoteleria({
        idEmpresa: Number(this.id_empresa),
        fechaIni: this.range.date_start ? this.range.mask_start : "2024-01-01",
        fechaFin: this.range.date_end ? this.range.mask_end : "2024-12-30",
      });
      this.showChart();
      this.loading = false;
      },
    //Dates
    captureDateStart(date) {
      this.range.date_start = date;
      this.range.mask_start = moment(date).format("YYYY-MM-DD");
      this.getData()
    },
    captureDateEnd(date) {
        this.range.date_end = date;
        this.range.mask_end = moment(date).format("YYYY-MM-DD");
        this.getData()
    },
    showChart() {
      if (this.hotel_dash.total_estados === undefined || this.hotel_dash.total_estados.length === 0 ) return;
        const data = {
            labels:[],
            colors:[],
            series:[]
        }
        this.hotel_dash.total_estados.forEach(element => {
            data.labels.push(element.nombre_estado)
            data.series.push(element.total)
            data.colors.push(element.color)
        });
        this.chartOptions.colors = data.colors
        this.chartOptions.labels = data.labels
        this.series = data.series
    },
    
  },
};
</script>
